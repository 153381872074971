import React from 'react';
import MapView from './components/MapViewAll';

import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { Amplify, I18n }  from 'aws-amplify';

import { Authenticator,Heading,Text,useTheme,
  View, Image, translations,
  Grid, Card, Button,Icon,
  Divider,Flex,
  ThemeProvider,Theme
} from '@aws-amplify/ui-react';

I18n.putVocabularies(translations);
I18n.setLanguage('ja');

Amplify.configure(awsExports);


const formFields = {
  confirmVerifyUser: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

const components = {

  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="logo" src="gvsIcon.svg"/>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; 2022 GASTEC CORPORATION All Rights Reserved
          <div>Ver. {process.env.REACT_APP_VERSION}</div>
        </Text>
      </View>
    );
  },

  VerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },

  ConfirmVerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};


const App: React.FC = () => {
  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut, user }) => (
        <main>
          
            <Flex           
              direction="colum"
              justifyContent="space-between"
              alignItems="stretch"
              alignContent="flex-start"
              wrap="nowrap"
              gap="0rem" 
              margin={8}
            >
              <View ><h3>GVS</h3></View>
              <View ><p onClick={signOut}>Sign out</p></View>
            </Flex>
            <View columnStart="1" columnEnd="-1"><Divider size="small"orientation="horizontal" /></View>

            
            <MapView />
            

            <View columnStart="1" columnEnd="-1"><Divider size="small"orientation="horizontal" /></View>

            <View textAlign = "center">
                &copy; 2022 GASTEC CORPORATION All Rights Reserved Ver. {process.env.REACT_APP_VERSION}
                
            </View>

          
        </main>
      )}
    </Authenticator>
  );
}

export default App;
