import * as React from 'react';
import { useCSVDownloader } from 'react-papaparse';

//現在日時取得関数 YYYYMMDDHHMMMのフォーマットで現在日時を取得、ファイル名のsuffixとして付ける
const padZero = (num) => {
  return (num < 10 ? "0" : "") + num;
};
const getCurrentDatetime = () => {
  const now = new Date();
  return "" + now.getFullYear() + padZero(now.getMonth() + 1) + padZero(now.getDate()) + padZero(now.getHours()) + padZero(now.getMinutes());
};

//CSVダウンロード関数　呼び出されるときに、パラメータとしてファイル名prefix、ボタン名、JSONデータをもらう
const CSVDownloader = (props) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const filename = props.filenameprefix + "_" + getCurrentDatetime();
  return (
    <CSVDownloader type={Type.Button} filename={filename} bom={true} config={{delimiter:","}} data={props.data}>
      {props.buttonname}
    </CSVDownloader>
  );
};

export default CSVDownloader;