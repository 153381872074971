import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, SVGOverlay,
        Marker, Popup, ImageOverlay, Circle, LayersControl, useMap,
        Polyline,
        useMapEvents} from "react-leaflet";
import { DivIcon, latLng, LatLng, LatLngBounds,icon, LatLngExpression } from "leaflet";

import icon8atImage from '../img/icon8at.png'
import iconCenterMark from '../img/center_mark.png'

import legendImage from '../img/legend.png'

import CSVDownloader from "./CSVDownloader";


import  { CSSProperties } from 'react';

import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';

import { Flex, View, Image, Grid, Card,
  Expander,ExpanderItem,Button
} from '@aws-amplify/ui-react';




const data = {
  points:[
    { x:10,y:10,value:0.005},
    { x:25,y:25,value:0.004},
    { x:50,y:50,value:0.003},
    { x:75,y:75,value:0.002},
    { x:80,y:80,value:0.001},
   ],
  tiles:[
    { x:10,y:0,value:10},
    { x:10,y:10,value:9},
    { x:10,y:20,value:8},
    { x:10,y:30,value:7},
    { x:10,y:40,value:6},
    { x:10,y:50,value:5},
    { x:10,y:60,value:4},
    { x:10,y:70,value:3},
    { x:10,y:80,value:2},
    { x:10,y:90,value:1},
  ]
}

const LAT_LENGTH = 110989.86 //緯度1度あたりの長さ　単位m

const oyamaInfo = new LatLng(32.738861182536716, 130.26300306080466);//雲仙お山の情報館別館
const positionG = new LatLng(35.439656, 139.441945);//GASTEC CORPORATION
const hachijyoPoint = new LatLng(33.07467900025468, 139.8125066017938);//八丈島地熱
const wasabisawa = new LatLng(38.98667730319013, 140.56895645789942) //山葵沢地熱

const nationalStadium = new LatLng(35.677851456300445, 139.7145897120037)//国立競技場

const oyamaInfoN: LatLng = new LatLng(32.738861182536716, 130.26300306080466)

const icon8at = icon({
  iconUrl: icon8atImage,
  iconSize: [20, 32],
  iconAnchor: [20/2, 32],
});

const centerMarkerIcon = icon({
  iconUrl: iconCenterMark,
  iconSize: [20, 19],
  iconAnchor: [20/2, 19],
});

const boundsN = new LatLngBounds(
  [oyamaInfo.lat - 0.00105, oyamaInfo.lng - 0.00125] ,
  [oyamaInfo.lat + 0.00105, oyamaInfo.lng + 0.00125]
  )

const boundsINIT = new LatLngBounds(
  [oyamaInfo.lat - 0.00105, oyamaInfo.lng - 0.00125] ,
  [oyamaInfo.lat + 0.00105, oyamaInfo.lng + 0.00125]
  )

const polylinesN: LatLngExpression[] = [
  [oyamaInfo.lat - 0.00105, oyamaInfo.lng - 0.00125] ,
  [oyamaInfo.lat - 0.00105, oyamaInfo.lng + 0.00125] ,
  [oyamaInfo.lat + 0.00105, oyamaInfo.lng + 0.00125] ,
  [oyamaInfo.lat + 0.00105, oyamaInfo.lng - 0.00125] ,
  [oyamaInfo.lat - 0.00105, oyamaInfo.lng - 0.00125] ,
]


const boundsMN = new LatLngBounds(
    [oyamaInfo.lat - 0.0105, oyamaInfo.lng - 0.0125] ,
    [oyamaInfo.lat + 0.0105, oyamaInfo.lng + 0.0125]
    )

var editMode: number = 0 //編集モード　1:境界設定 2:測定点設定 

let selectedPositions: LatLng[] = []

var clickMapCnt = 0

var decidedBoundsFlag: Boolean = false //境界を設定した有無のフラグ
var decidedBounds: LatLngBounds //境界
var polylinesBounds: LatLngExpression[] //境界線

let decidedCenter: LatLng = oyamaInfo

let decideMPointFlag: Boolean = false //測定点を設定した有無のフラグ
//測定点の境界内の位置(パーセント)
let mPoints = [{x:"0", y:"0", mNumber: 0, value:0}] 

let meshSize: number = 25//メッシュの分割数　10～100とする。
//メッシュ
let mesh2 = [
  {x:"1",y:"1"}
  ]
//カラーメッシュ
let colorMesh = [
  {x:"1",y:"1",value:0}
]

//アニメーション用カラーメッシュ
let animationCMesh:any[] = []
//アニメーション用jsonのインデックス番号
let animationIndex = 0

//濃度分布のCSVアップロードのうけ
var dataJsonCMesh:any = {
  data:[],
  errors:[],
  meta:[]
}
//測定点のCSVアップロードのうけ
let dataJsonMPoints: any = {
  data: [],
  errors: [],
  meta: []
}
//境界のCSVアップロードのうけ
let dataJsonBounds: any ={
  data: [],
  errors: [],
  meta: []
}

let uploadedFlags = { 
  uploadedBounds: false,
  uploadedMPoints: false,
  uploadedCMesh: false
}
let uploadState: number = 0
let boundsCenter: LatLng
let centerBounds =[{
  centerLat: 0.0,
  centerLng: 0.0,
  boundsNWLat: 0.0,
  boundsNWLng: 0.0,
  boundsNELat: 0.0,
  boundsNELng: 0.0,
  boundsSELat: 0.0,
  boundsSELng: 0.0,
  boundsSWLat: 0.0,
  boundsSWLng: 0.0,
  lengthOS:0.0
}]

function LocationMarker(): null | any {

  const [selectedPosition, setPosition] = useState(oyamaInfo)
  const [selectedCenter,setCenterPosition] = useState(oyamaInfo)
  const [selectedOuter,setOuterPosition] = useState(oyamaInfo)
  const [boundsNS,setBoundsNS] = useState(boundsINIT)
  const [selectedPosis, setSelectedPosis] = useState(selectedPositions)
  const [polyNS,setPolyline] = useState(polylinesN)

  const map = useMapEvents({
    click(e) {
      
      if(editMode == 1){//境界設定モード
        clickMapCnt += 1
        //測定点のアイコンをクリア
        setSelectedPosis(selectedPositions)
        if(clickMapCnt > 2) clickMapCnt = 0
        //setPosition(e.latlng)
        
        if(clickMapCnt == 1){ //中心位置を配置した
          setCenterPosition(e.latlng)
          decidedCenter = e.latlng

        }
        if(clickMapCnt == 2){//半径位置を配置した
          setOuterPosition(e.latlng)
          //x,y差分計算
          const xDistance = Math.abs(e.latlng.lng - selectedCenter.lng)
          const yDistance = Math.abs(e.latlng.lat - selectedCenter.lat)
          var diffX = 0
          var diffY = 0
          const compe = getLatLngCompe(e.latlng.lat)
          if(xDistance >= yDistance){
            diffX = xDistance
            diffY = xDistance * compe
          }
          else {
            diffX = yDistance * 1/compe
            diffY = yDistance
          }

          console.log("NowP:"+e.latlng)
          console.log("xDistance="+xDistance)
          console.log("yDistance="+yDistance)
          console.log("diffX="+diffX)
          console.log("diffY="+diffY)
          console.log("compe="+compe)
          //RECTの開始点を算出
          const startPosi = new LatLng(selectedCenter.lat - diffY,selectedCenter.lng - diffX)
          //RECTの終了点を算出
          const endPosi = new LatLng(selectedCenter.lat + diffY,selectedCenter.lng + diffX)
          //Boundsを設定
          const selectedBounds = new LatLngBounds(startPosi,endPosi)
          setBoundsNS(selectedBounds)
          //境界を設定した。
          decidedBounds = selectedBounds
          decidedBoundsFlag = true
          //緯度の長さを算出
          const latLength = (selectedBounds.getNorth() - selectedBounds.getSouth()) * LAT_LENGTH
          console.log("緯度長" + String(latLength))
          //measuringPoints.splice(0)//一端測定点を削除
          polylinesBounds = [selectedBounds.getNorthWest(),selectedBounds.getNorthEast(),selectedBounds.getSouthEast(),selectedBounds.getSouthWest(),selectedBounds.getNorthWest()]
          setPolyline(polylinesBounds)
          console.log("DecidedBounds:NW" + decidedBounds.getNorthWest() +",:SE " + decidedBounds.getSouthEast())
          //中心座標と境界をJSONとして保存
          centerBounds = [] //一旦空にしておいて
          centerBounds = [{
            centerLat: selectedCenter.lat,
            centerLng: selectedCenter.lng,
            boundsNWLat: selectedBounds.getNorthWest().lat,
            boundsNWLng: selectedBounds.getNorthWest().lng,
            boundsNELat: selectedBounds.getNorthEast().lat,
            boundsNELng: selectedBounds.getNorthEast().lng,
            boundsSELat: selectedBounds.getSouthEast().lat,
            boundsSELng: selectedBounds.getNorthEast().lng,
            boundsSWLat: selectedBounds.getSouthWest().lat,
            boundsSWLng: selectedBounds.getSouthWest().lng,
            lengthOS: latLength
          }]

        }

      }
      else if(editMode == 2){//測定点設定モード
        clickMapCnt += 1
        
        if(decidedBoundsFlag){//境界が決定している場合
         
          selectedPositions.push(e.latlng) //選択した配置に追加
          setSelectedPosis([...selectedPosis,e.latlng])//useStateはpushだと再描画されない、新しい配列を追加すると再描画されるのでスプレット構文を使用
          //境界の中でのx,y位置のパーセント取得
          if(decidedBoundsFlag){//境界が既に決定している時

            const northWest = boundsNS.getNorthWest() //境界の北西位置取得
            const northEast = boundsNS.getNorthEast() //
            const southWest = boundsNS.getSouthWest()
            const southEast = boundsNS.getSouthEast() //境界の南東位置取得
            
            console.log("NW" + northWest)
            console.log("SE" + southEast)
            const xp: string = ((e.latlng.lng - northWest.lng)/(southEast.lng - northWest.lng) * 100).toFixed(0)//x位置パーセント取得
            const yp: string = ((northWest.lat - e.latlng.lat)/(northWest.lat - southEast.lat) * 100).toFixed(0)//y位置パーセント取得
            const count = selectedPositions.length
            if(selectedPositions.length == 1)
            {
              mPoints = []//初回は、初期値で作ったものを削除するため空にしてから入れる
            }
            mPoints.push({x:xp,y:yp,mNumber:count,value:0})
            console.log("xp:" + xp + ",yp:" + yp)
            decideMPointFlag = true//測定点を配置したフラグセット
  
          }
          
        }
      }

      else {
        
      }
    },
  })

  const Output = () =>{
    if(selectedPosition != null){
      if(editMode == 1){//境界設定モード
        if(clickMapCnt == 1)//中心位置指定
        {
          return <Marker position = {selectedCenter} icon={centerMarkerIcon} />
        }
        else if(clickMapCnt == 2){//半径位置指定
          return (
            <div>
              <Marker position = {selectedCenter} icon = {centerMarkerIcon} />
              <Marker position = {selectedOuter} icon={centerMarkerIcon} />
              <Polyline pathOptions={{color:"red"}} positions={polyNS} />   
              <SVGOverlay  attributes={{ stroke: 'white' }} bounds={boundsNS}>
                <rect x="0" y="0" width="100%" height="100%" fill="blue" opacity={0.5}/>
              </SVGOverlay>
            </div>

          )
        }
      }
      else if(editMode == 2){//測定箇所配置モード
        
        if(selectedPositions.length > 0)
        return (
          <div>
            {selectedPosis.map((item,i) =><Marker key={i} position = {item} icon={icon8at}/>) }
          </div> 
        )
        else{
          return(null)
        }
        
      }

      
    }
    else{
      return null
    }
  }

  return (
    <div>
      {Output()}
    </div>
  )

}

//緯度経度の比率補正
const getLatLngCompe = (la:number)=>{

  return -0.0107*la + 1.1953

}

function PlaceBounds(){

  if(decidedBoundsFlag){
    return (
      <Polyline pathOptions={{color:"blue"}} positions={polylinesBounds} />   
      )
  }
  else {
    return null
  }

  
  
}

function PlaceMarker(){
  return (
    <div>
      {selectedPositions.map((item,i) =><Marker key = {i}position = {item} icon={icon8at}/>) }
    </div> 
  )
}

function PlaceMeasuringPoint(){
  if(decidedBoundsFlag && decideMPointFlag){
      if(mPoints.length > 0){
        return(
          <SVGOverlay attributes={{ stroke: 'white' }} bounds={decidedBounds}>
            {mPoints.map((item,i) => <circle key = {i} r="5" cx={item.x+"%"} cy={item.y+"%"} fill="yellow" opacity={0.8}/>)}
            {mPoints.map((item,i) => <text key = {i} x={item.x +"%"} y={item.y +"%"} stroke="black">{"M" + String(item.mNumber)}</text>)}
          </SVGOverlay>
        )
      }
      else{
        return null
      }
  }
  else{
    return null
  }
}



function PlaceColorTiles(){

  if(decidedBoundsFlag && uploadedFlags.uploadedCMesh){
    return(
      <SVGOverlay bounds={decidedBounds}>
        {dataJsonCMesh.data.map((item: { x: string ; y: string; value: Number; })=>(
          <rect key = {item.x + "_" + item.y} x = {item.x + "%"} y={item.y + "%"} width={String(100/meshSize) +"%"}  height={String(100/meshSize) +"%"}  fill= {getLevelColor(item.value)} opacity = {getLevelOpacity(item.value)} />
        ))}
      </SVGOverlay>  
    )
  }
  else{
    return null
  }

}

//境界を表示
function PlaceMeshTiles(){
  if(decidedBoundsFlag){
    return(
      <SVGOverlay attributes={{ stroke: 'white' }}  bounds={decidedBounds}>
        {mesh2.map((item,i) => (
          <rect key = {i} x = {item.x + "%"} y={item.y + "%"} width={String(100/meshSize) +"%"} height={String(100/meshSize) + "%"} fill= "gray" opacity = "0.1" />
        ))}
      </SVGOverlay>  
    )
  }
  else{
    return null
  }

}

//凡例を配置
function PlaceLegend(){
  return (
    <div className='leaflet-bottom leaflet-right'>
      <img src={legendImage} alt="" />
      <p>　</p>
    </div>
  )
}

//CSVダウンローダー表示非表示
function DispCSVDownloader(){
  if(decideMPointFlag && decidedBoundsFlag){
    return(
      <div>
        <View margin={4}>
          <CSVDownloader data={centerBounds} filenameprefix="bounds" buttonname="境界CSV Download" />
        </View>
        <View margin={4}>
          <CSVDownloader data={mPoints} filenameprefix="mpoints" buttonname="測定点CSV Download" />
        </View>
        <View margin={4}>
          <CSVDownloader data={colorMesh} filenameprefix={"cmesh_msize" + String(meshSize) } buttonname="濃度分布CSV Download" />
        </View>
      </div>
    )

  }
  else{
    return (null)
  }
}

//濃度分布アニメーション表示
function AnimationColorMesh(){
  if(uploadedFlags.uploadedBounds && uploadedFlags.uploadedMPoints && uploadedFlags.uploadedCMesh){

    return(
      <SVGOverlay bounds={decidedBounds}>
        {animationCMesh[animationIndex].map((item:any,i:any)=>(
          <rect key = {i} x = {item.x + "%"} y={item.y + "%"} width={String(100/meshSize) +"%"}  height={String(100/meshSize) +"%"}  fill= {getLevelColor(item.value)} opacity = {getLevelOpacity(item.value)} />
        ))}
      </SVGOverlay>  
    )
  }
  else{
    return null
  }
}

//濃度から濃度色レベル取得
const getLevelColor = (value:Number) =>{
  var rt = "white"
  if(value <= 1) rt       = "white"
  else if(value <= 2) rt  = "aqua"
  else if(value <= 3) rt  = "blue"
  else if(value <= 4) rt  = "navy"
  else if(value <= 5) rt  = "lime"
  else if(value <= 6) rt  = "green"
  else if(value <= 7) rt  = "yellow"
  else if(value <= 8) rt  = "fuchsia"
  else if(value <= 9) rt  = "red"
  else if(value <= 10) rt = "maroon"
  return rt
} 
//濃度レベルから透明度取得
const getLevelOpacity = (value:Number) =>{
  var rt = "0.5"
  if (value <=1) rt = "0.0" //レベル1の時は透明
  return rt
}





const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  } as CSSProperties,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  } as CSSProperties,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};
//CSV Reader
function CSVReader() {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const[pasedJson, setParsedJson] = useState()

  

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        setParsedJson(results)

        setZoneHover(false);

        if(uploadState == 0){//境界CSVのアップロードが完了した
          dataJsonBounds = Object.create(results)
          console.log("uploadState=0")
          uploadedFlags.uploadedBounds = true
          //decidedCenterに代入
          decidedCenter = new LatLng(dataJsonBounds.data[0].centerLat,dataJsonBounds.data[0].centerLng)
          //decidedBoundsに代入
          decidedBounds  = new LatLngBounds(
            [dataJsonBounds.data[0].boundsNWLat, dataJsonBounds.data[0].boundsNWLng] ,
            [dataJsonBounds.data[0].boundsSELat, dataJsonBounds.data[0].boundsSELng]
          )
          //境界のpolylineBoundsも設定
          polylinesBounds = [
            [dataJsonBounds.data[0].boundsNWLat,dataJsonBounds.data[0].boundsNWLng],
            [dataJsonBounds.data[0].boundsNELat,dataJsonBounds.data[0].boundsNELng],
            [dataJsonBounds.data[0].boundsSELat,dataJsonBounds.data[0].boundsSELng],
            [dataJsonBounds.data[0].boundsSWLat,dataJsonBounds.data[0].boundsSWLng],
            [dataJsonBounds.data[0].boundsNWLat,dataJsonBounds.data[0].boundsNWLng]
          ]

          
          decidedBoundsFlag = true //境界配置のフラグセット
       
        }

        else if(uploadState == 1){ //測定点CSVのアップロードが完了した
          dataJsonMPoints = Object.create(results)
          uploadedFlags.uploadedMPoints = true
          //mPointsのオブジェクトに代入
          mPoints=[]//一度空にして mPointsに入れる
          dataJsonMPoints.data.map((item:any)=>{
            mPoints.push({x: item.x, y: item.y, mNumber: item.mNumber,value:item.value})
          })
          decideMPointFlag = true //測定点配置のフラグをセット

        }
        else if(uploadState == 2){//濃度分布CSVのアップロードが完了した
          dataJsonCMesh =Object.create(results)//JSON オブジェクトとして保存
          console.log("uploadState=2")
          uploadedFlags.uploadedCMesh = true//uploadしたフラグセット

          //アニメーション用にも作成
          animationCMesh.push([]) //空の配列を追加
          dataJsonCMesh.data.map((item:{ x: string ; y: string; value: number; }) =>{
            animationCMesh[animationCMesh.length - 1].push({x:item.x, y: item.y, value: item.value})
          })
          console.log(animationCMesh)
        }

      }}
      config={{
        header: true,
        skipEmptyLines: true
      }}
      accept="text/csv"
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
      }: any) => (
        <>
          <div
            {...getRootProps()}
            style={Object.assign(
              {},
              styles.zone,
              zoneHover && styles.zoneHover
            )}
          >
            {acceptedFile ? (
              <>
                <div style={styles.file}>
                  <div style={styles.info}>
                    <span style={styles.size}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span style={styles.name}>{acceptedFile.name}</span>
                  </div>
                  <div style={styles.progressBar}>
                    <ProgressBar />
                  </div>
                  <div
                    {...getRemoveFileProps()}
                    style={styles.remove}
                    onMouseOver={(event: Event) => {
                      event.preventDefault();
                      setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                    }}
                    onMouseOut={(event: Event) => {
                      event.preventDefault();
                      setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                    }}
                  >
                    <Remove color={removeHoverColor} />
                  </div>
                </div>
              </>
            ) : (
              'Drop CSV file here or click to upload'
            )}
          </div>
        </>
      )}
    </CSVReader>
  );
}


function makeMesh(mSize: number){
    //メッシュ2を作る
    mesh2 = [] //一度クリア
    for(let xp = 0; xp< mSize; xp++){
      for(let yp= 0; yp < mSize; yp++){
        const xPosi = String(xp*100/mSize);
        const yPosi = String(yp*100/mSize);
        mesh2.push({x:xPosi,y:yPosi});
        }
    }

    //カラーメッシュを作る
    colorMesh = [] //一度クリア
    for(let xp = 0; xp< mSize; xp++){
      for(let yp= 0; yp < mSize; yp++){
        const xPosi = String(xp*100/mSize);
        const yPosi = String(yp*100/mSize);
        const value = 1
        colorMesh.push({x:xPosi,y:yPosi,value:value});
        }
    }

}


const MapView = () => {

  const [positionC, setPosition] = useState(oyamaInfo);
  const [count,setCount] = useState(0);
  const [zoom, setZoom] = useState(17);
  const [infoSBTxt, setInfoSBTxt] = useState("")
  const [buttonSBTxt,setButtonSBTxt] = useState("境界設定を行う")

  const [checkedValue, setCheckedValue] = useState("25")
  const [inputtextValue, setInputTextValue] = useState("")
  const [text, setText] = useState("")
  const [selectedValue, setSelectedValue] = useState("unzen")

  const [infoTxt, setInfoTxt] = useState("境界CSVをUpload")
  const [btnTxt, setBtnTex] = useState("次へ")

  const [infoAniTxt, setInfoAniTxt] = useState("")


 //upload切替ボタンクリックイベント
 const handleClickUpBtn= async () =>{
  uploadState +=1
  if(uploadState >2)uploadState=2
      
    if(uploadState == 0){
      setInfoTxt("境界CSV(bounds)をアップロード")
      setBtnTex("次へ")
      

    }
    else if(uploadState == 1){
      setPosition(decidedCenter) //アップロードした中心位置をセット
      setInfoTxt("測定点CSV(mpoints)をアップロード")
    }
    else if(uploadState == 2){
      setInfoTxt("濃度分布CSV(cmesh)をアップロード(" + String(animationCMesh.length) + ")")
      setBtnTex("追加")
    }

  }

  //初回meshを作る
  makeMesh(meshSize)

  //地図の中心を移動
  function ChangeMapCenter(){

      const map = useMap()
      map.panTo(positionC)
  
    return null
  }

  //セレクトボックスを選択したハンドラ
  const handleChangeSelectBox = (e: any) =>{
    setSelectedValue(e.target.value)
    
    //選択した場所をMapの中心へ
    if(e.target.value == "unzen"){
      
      setPosition(oyamaInfo)//お山の情報館
    }
    else if(e.target.value == "hachijyo"){
      setPosition(hachijyoPoint) //八丈島
    }

  }
  //テキスト入力の状態を更新するハンドラ
  const handleChangeText = (e:any) => {
    setInputTextValue(e.target.value)
  }
  //空の文字列に更新するハンドラ
  const handleClickClear = (e:any) =>{
    setText(inputtextValue)
    setInputTextValue("")//フォームを空にする
  }

  //ラジオボタンでメッシュサイズを選択するハンドラ
  const handleChange = (e:any) => {
    setCheckedValue(e.target.value)
    meshSize = Number(e.target.value)//数値に変換
    console.log("meshSize= "+String(meshSize))
    //メッシュを作る
    makeMesh(meshSize)

  }

  //モード切替ボタンクリックイベント
  const hanldeClick= async () =>{
    //イベント発生時に処理
    //setPosition(positionG)



    editMode += 1
    if(editMode >= 3){
      editMode = 0
    } 

    if(editMode == 1){//境界設定モード
      clickMapCnt = 0
      setInfoSBTxt("境界の中心と範囲を地図上で選択")
      setButtonSBTxt("測定点配置を行う")
 
      //境界と測定点を全クリア
      decideMPointFlag = false
      decidedBoundsFlag = false
      selectedPositions = []
      centerBounds = []
      mPoints = []
      colorMesh = []

    }
    else if(editMode == 2){//測定点配置モード
      clickMapCnt = 0
      setPosition(decidedCenter) //中心位置を切替
      setInfoSBTxt("測定点を地図上に配置")
      setButtonSBTxt("終了する")
    }
    else {
      setInfoSBTxt("")
      setButtonSBTxt("境界設定を行う")
    }
  }

  //アニメーション進めるボタン
  const handleClickAnimeBtnForward= async () =>{
    animationIndex += 1
    if(animationIndex > animationCMesh.length - 1) animationIndex = 0
    setInfoAniTxt("(" + String(animationIndex + 1 ) + "/" + String(animationCMesh.length) + ")")
  }
  //アニメーション戻るボタン
  const handleClickAnimeBtnBack= async () =>{
    animationIndex -= 1
    if(animationIndex < 0) animationIndex = animationCMesh.length - 1
    setInfoAniTxt("(" + String(animationIndex + 1 ) + "/" + String(animationCMesh.length) + ")")
  }
  

  return (
    <div>

        <Grid
          columnGap="0.5rem"
          rowGap="0rem"
          templateColumns="1fr 4fr"
          templateRows="1fr"
        >
          <View  
            columnStart="1"
            columnEnd="2" margin={8}>
            <Expander type="single" isCollapsible={true} margin={16}>
              <ExpanderItem title="CSV作成、ダウンロード" value="item-1">
                <Flex 
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="nowrap"
                  gap="1rem" 
                >
                  <View>
                  <p><b>1.場所を指定</b></p>
                    <select value={selectedValue} onChange={handleChangeSelectBox}>
                      <option value="unzen">雲仙</option>
                      <option value="hachijyo">八丈島</option>
                    </select>
                    
                  </View>
                  <View>
                  <p><b>2.境界,測定点を設定</b></p>
                    <p><b>{infoSBTxt}</b></p>
                    <Button onClick={hanldeClick}>{buttonSBTxt}</Button>
                  </View>
                  <View>
                  <p><b>3.メッシュサイズ</b></p>
                    <label><input type={"radio"} value={"10"} onChange = {handleChange} checked={checkedValue === "10"}/>10</label>
                    <label><input type={"radio"} value={"25"} onChange = {handleChange} checked={checkedValue === "25"}/>25</label>
                    <label><input type={"radio"} value={"50"} onChange = {handleChange} checked={checkedValue === "50"}/>50</label>
                  </View>

                  <DispCSVDownloader />

                </Flex>
              </ExpanderItem>
              <ExpanderItem title="CSV アップロード" value="item-2">
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="nowrap"
                  gap="1rem" 
                  margin={8}>

                  <View>
                    <p>{infoTxt}</p>
                    <CSVReader />
                    <br/>
                    <Button onClick={handleClickUpBtn}>{btnTxt}</Button>
                    <br/>
                    <br/>

                    <p>{"アップロード分布図数 " + animationCMesh.length}</p>
                    <p><b>分布図切替え{infoAniTxt}</b></p>
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="1rem" 
                      >
                      <Button onClick={handleClickAnimeBtnBack}>前</Button>
                      <Button onClick={handleClickAnimeBtnForward}>後</Button>
                    </Flex>
                   
                    
                  </View>
      
                </Flex>
              </ExpanderItem>
            </Expander>

          </View>


          <View  
            columnStart="2"
            columnEnd="-2"
            margin={8}>
            <MapContainer center={positionC} zoom={zoom} style={{ height: "85vh" }} >
          
              <TileLayer
                //国土地理院 標準
                //attribution='&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
                //url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png" 
                //国土地理院 写真
                attribution='&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
                url="https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg" 
              />

              <LayersControl position="topright">

                <LayersControl.Overlay checked name="測定点">
                  <PlaceMeasuringPoint/>         
                </LayersControl.Overlay>

                <LayersControl.Overlay name="メッシュ">
                  <PlaceMeshTiles/>
                </LayersControl.Overlay>

                <LayersControl.Overlay checked name="境界">              
                  <PlaceBounds/>                  
                </LayersControl.Overlay>

                <LayersControl.Overlay checked name="濃度分布">
                  <AnimationColorMesh/>
                </LayersControl.Overlay>

              </LayersControl>
          
              <LocationMarker />

              <PlaceLegend/>

              <ChangeMapCenter />

            </MapContainer>
          </View>
      </Grid>
    </div>
  );
};

export default MapView;

function lng(lat: any, arg1: number, lng: any, arg3: number) {
  throw new Error("Function not implemented.");
}